import * as React from "react";

import s from "./HeroFullWidth.scss";

interface IHeroFullWidthProps {
  heroimage: React.ReactNode;
}

export const HeroFullWidth = ({ heroimage }: IHeroFullWidthProps) => (
  <div className={s.herofullwidth}>{heroimage}</div>
);
