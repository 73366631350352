import * as React from "react";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";

import s from "./HalfBlockBackgroundImage.scss";

interface IHalfBlockBackgroundImageProps {
  children: React.ReactNode;
}

export const HalfBlockBackgroundImage = ({
  children
}: IHalfBlockBackgroundImageProps) => (
  <div className={s.halfblockbackgroundimage}>
    <Container>
      <div className={s.halfblockbackgroundimage__block}>
        <Row>
          <div className={s.halfblockbackgroundimage__content}>{children}</div>
        </Row>
      </div>
    </Container>
  </div>
);
