import * as React from "react";
import Fade from "react-reveal/Fade";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";

import s from "./VieIntroduction.scss";

interface IVieIntroductionProps {
  heading: string;
  description: React.ReactNode;
}

export const VieIntroduction = ({
  heading,
  description
}: IVieIntroductionProps) => (
  <div className={s.vieintroduction}>
    <Container>
      <div className={s.vieintroduction__block}>
        <Row>
          <div className={s.vieintroduction__col}>
            <Fade delay={200}>
              <h2 className={s.vieintroduction__heading}>{heading}</h2>
            </Fade>
            <Fade delay={400}>
              <div className={s.vieintroduction__description}>
                {description}
              </div>
            </Fade>
          </div>
        </Row>
      </div>
    </Container>
  </div>
);
