import * as React from "react";

import s from "./FullWidthBackgroundImage.scss";

interface IFullWidthBackgroundImageProps {
  image: React.ReactNode;
}

export const FullWidthBackgroundImage = ({
  image
}: IFullWidthBackgroundImageProps) => (
  <div className={s.fullwidthbackgroundimage}>{image}</div>
);
