import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Fade from "react-reveal/Fade";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";

import CoolGelIcon from "assets/svg/cool-gel-icon.svg";
import DeepPocketSpringsIcon from "assets/svg/deep-pocket-springs-icon.svg";
import RemovableCoverIcon from "assets/svg/removable-cover-icon.svg";
import MemoryFoamIcon from "assets/svg/memory-foam-icon.svg";

import s from "./VieIsAbout.scss";

export const VieIsAbout = () => {
  const data = useStaticQuery(graphql`
    fragment vieisaboutImages on File {
      childImageSharp {
        fluid(maxWidth: 2500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      imageVieMattressMemoryFoam: file(
        relativePath: { eq: "vie-mattress-memory-foam.jpg" }
      ) {
        ...vieisaboutImages
      }
      imageVieMattressSprings: file(
        relativePath: { eq: "vie-mattress-springs.jpg" }
      ) {
        ...vieisaboutImages
      }
      imageVieMattressTexture: file(
        relativePath: { eq: "vie-mattress-texture.jpg" }
      ) {
        ...vieisaboutImages
      }
    }
  `);
  return (
    <div className={s.vieisabout}>
      <Container>
        <div className={s.vieisabout__block}>
          <Row>
            <Fade delay={200}>
              <div className={s.vieisabout__heading}>
                <h2>Vie is about comfort, health, style and luxury.</h2>
              </div>
            </Fade>
          </Row>
          <Row>
            <div className={s.vieisabout__left}>
              <div className={s.vieisabout__col}>
                <Fade left delay={200}>
                  <BackgroundImage
                    Tag="section"
                    fluid={
                      data.imageVieMattressMemoryFoam.childImageSharp.fluid
                    }
                    backgroundColor={`#353a3c`}
                  />
                </Fade>
              </div>
              <div className={s.vieisabout__col}>
                <div>
                  <Fade delay={400}>
                    <div className={s.vieisabout__description}>
                      <h3>Hybrid design</h3>
                      <p>
                        All Vie mattresses are designed with pocket springs and
                        multiple memory foam and Cool Gel layers to give you the
                        best combination of comfort, cooling and body moulding
                        support.
                      </p>

                      <h3>Full size pocket springs</h3>
                      <p>
                        Many hybrid mattresses use only small ‘micro’ springs.
                        All Vie mattress utilise full size springs to deliver
                        the maximum amount of support and a more comfortable
                        sleep.
                      </p>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div className={s.vieisabout__right}>
              <div className={s.vieisabout__col}>
                <div>
                  <Fade delay={400}>
                    <div className={s.vieisabout__description}>
                      <h3>Premium memory foam</h3>
                      <p>
                        Thick, multiple layers of memory foam are combined in a
                        Vie mattress with up to 3,000 Vie pocket springs to give
                        the perfect ratio of medium firm support and provide a
                        breathable sleeping surface with cooling airflow.
                      </p>

                      <h3>Health</h3>
                      <p>
                        Good sleep and rest are pivotal to a happy and healthier
                        life. Whether you are a busy professional, enjoy sports,
                        or just enjoy an active life, you will find a Vie
                        mattress provides perfect spinal support and alignment
                        and welcome relief for aching back and limbs.
                      </p>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className={s.vieisabout__col}>
                <Fade right delay={200}>
                  <BackgroundImage
                    Tag="section"
                    fluid={data.imageVieMattressSprings.childImageSharp.fluid}
                    backgroundColor={`#353a3c`}
                  />
                </Fade>
              </div>
            </div>
          </Row>
          <Row>
            <div className={s.vieisabout__left}>
              <div className={s.vieisabout__col}>
                <Fade left delay={200}>
                  <BackgroundImage
                    Tag="section"
                    fluid={data.imageVieMattressTexture.childImageSharp.fluid}
                    backgroundColor={`#353a3c`}
                  />
                </Fade>
              </div>
              <div className={s.vieisabout__col}>
                <div>
                  <Fade delay={400}>
                    <div className={s.vieisabout__description}>
                      <h3>Luxury &amp; style</h3>
                      <p>
                        Vie products are styled in the UK and incorporate
                        contemporary design features with luxurious finishing
                        and premium components that will add a touch of style
                        and glamour to any bedroom. From the luxurious covers,
                        to the sumptuous interior design, Vie is the new name
                        for luxury in Hybrid mattress design.
                      </p>

                      <h3>Life</h3>
                      <p>
                        Vie means life. Products that have been scientifically
                        tested to ensure they provide exceptional performance,
                        so that you can live your life to the full. Every day.
                        C’est la Vie!
                      </p>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div className={s.vieisabout__icons}>
              <div className={s.vieisabout__col}>
                <ul>
                  <Fade delay={200}>
                    <li>
                      <div>
                        <CoolGelIcon />
                      </div>
                      <span>Cool gel layer</span>
                    </li>
                  </Fade>
                  <Fade delay={400}>
                    <li>
                      <div>
                        <DeepPocketSpringsIcon />
                      </div>
                      <span>Deep pocket springs</span>
                    </li>
                  </Fade>
                  <Fade delay={600}>
                    <li>
                      <div>
                        <RemovableCoverIcon />
                      </div>
                      <span>Removable cover</span>
                    </li>
                  </Fade>
                  <Fade delay={800}>
                    <li>
                      <div>
                        <MemoryFoamIcon />
                      </div>
                      <span>Memory foam</span>
                    </li>
                  </Fade>
                </ul>
              </div>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  );
};
