import * as React from "react";
import Fade from "react-reveal/Fade";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";

import s from "./Intro.scss";

interface IIntroProps {
  heading: string;
  description: React.ReactNode;
}

export const Intro = ({ heading, description }: IIntroProps) => (
  <div className={s.intro}>
    <Container>
      <div className={s.intro__block}>
        <Row>
          <div className={s.intro__col}>
            <Fade delay={200}>
              <h2 className={s.intro__heading}>{heading}</h2>
            </Fade>
            <Fade delay={400}>
              <div className={s.intro__description}>{description}</div>
            </Fade>
          </div>
        </Row>
      </div>
    </Container>
  </div>
);
