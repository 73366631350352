import * as React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Link from "gatsby-link";
import BackgroundImage from "gatsby-background-image";
import Fade from "react-reveal/Fade";

import { HeroFullWidth } from "components/hero-images/hero-full-width/HeroFullWidth";
import { Intro } from "components/intro/Intro";
import { HalfBlockBackgroundImage as VieFourBlocks } from "components/content/half-block-background-image/HalfBlockBackgroundImage";
import { FullBlockBackgroundImage as VieFullBlock } from "components/content/full-block-background-image/FullBlockBackgroundImage";
import { HalfBlockBackgroundImage as VieTwoBlocks } from "components/content/half-block-background-image/HalfBlockBackgroundImage";
import { VieIntroduction } from "components/vie-introduction/VieIntroduction";
import { FullWidthBackgroundImage as VieDeign } from "components/content/full-width-background-image/FullWidthBackgroundImage";
import { VieProducts as VieMattress } from "components/vie-products/VieProducts";
import { VieIsAbout } from "components/vie-is-about/VieIsAbout";
import { FullWidthBackgroundImage as VieLove } from "components/content/full-width-background-image/FullWidthBackgroundImage";
import { VieProducts as ViePillow } from "components/vie-products/VieProducts";
import { VieGallery } from "components/vie-gallery/VieGallery";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";

import ChevronRightSolid from "assets/svg/chevron-right-solid.svg";

import VideoMP4 from "assets/videos/little-girl-jumping.mp4";
import VideoWEBM from "assets/videos/little-girl-jumping.webm";

export default () => {
  const data = useStaticQuery(graphql`
    fragment indexImages on File {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      imageHero: file(relativePath: { eq: "hero.jpg" }) {
        ...indexImages
      }
      imageVieIsAboutComfort: file(
        relativePath: { eq: "vie-is-about-comfort.jpg" }
      ) {
        ...indexImages
      }
      imageVieIsAboutHealth: file(
        relativePath: { eq: "vie-is-about-health.jpg" }
      ) {
        ...indexImages
      }
      imageVieIsAboutStyle: file(
        relativePath: { eq: "vie-is-about-style.jpg" }
      ) {
        ...indexImages
      }
      imageVieIsAboutLuxury: file(
        relativePath: { eq: "vie-is-about-luxury.jpg" }
      ) {
        ...indexImages
      }
      imageVieDesign: file(relativePath: { eq: "vie-design.jpg" }) {
        ...indexImages
      }
      imageVieMattressMemoryFoam: file(
        relativePath: { eq: "vie-mattress-memory-foam.jpg" }
      ) {
        ...indexImages
      }
      imageVieMemoryOnTealSide: file(
        relativePath: { eq: "vie-memory-on-teal-side.jpg" }
      ) {
        ...indexImages
      }
      imageVieLove: file(relativePath: { eq: "vie-love.jpg" }) {
        ...indexImages
      }
      imageViePillowOnTealFront: file(
        relativePath: { eq: "vie-pillow-on-teal-front.jpg" }
      ) {
        ...indexImages
      }
      imageVieGallery: file(relativePath: { eq: "vie-gallery.jpg" }) {
        ...indexImages
      }
    }
  `);
  return (
    <>
      <Helmet title="The pinnacle of luxury hybrid mattress design" />

      <HeroFullWidth
        heroimage={
          <>
            <BackgroundImage
              Tag="section"
              fluid={data.imageHero.childImageSharp.fluid}
              alt="Vie Premier Hybrid Mattress superking hero"
              backgroundColor={`#353a3c`}
            >
              <Container>
                <div>
                  <Row>
                    <div>
                      <h1>Bring your bedroom to life.</h1>
                    </div>
                  </Row>
                </div>
              </Container>
            </BackgroundImage>
          </>
        }
      />

      <Intro
        heading="The Vie luxury hybrid mattress range"
        description={
          <>
            <p>
              Vie represents the pinnacle of luxury hybrid mattress design.
              Comfortable. Healthy. Affordable. Luxurious.
            </p>
          </>
        }
      />

      <VieFourBlocks
        children={
          <>
            <Fade delay={200}>
              <div>
                <BackgroundImage
                  Tag="section"
                  fluid={data.imageVieIsAboutComfort.childImageSharp.fluid}
                  alt="Vie is about comfort"
                  backgroundColor={`#353a3c`}
                >
                  <h2>Vie is about comfort</h2>
                </BackgroundImage>
              </div>
            </Fade>

            <Fade delay={400}>
              <div>
                <BackgroundImage
                  Tag="section"
                  fluid={data.imageVieIsAboutHealth.childImageSharp.fluid}
                  alt="Vie is about health"
                  backgroundColor={`#353a3c`}
                >
                  <h2>Vie is about health</h2>
                </BackgroundImage>
              </div>
            </Fade>

            <Fade delay={600}>
              <div>
                <BackgroundImage
                  Tag="section"
                  fluid={data.imageVieIsAboutStyle.childImageSharp.fluid}
                  alt="Vie is about style lifestyle"
                  backgroundColor={`#353a3c`}
                >
                  <h2>Vie is about style</h2>
                </BackgroundImage>
              </div>
            </Fade>

            <Fade delay={800}>
              <div>
                <h2>
                  Vie is about <span>life</span>
                </h2>
                <video loop muted autoPlay playsInline>
                  <source src={VideoMP4} type="video/mp4" />
                  <source src={VideoWEBM} type="video/webm" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Fade>
          </>
        }
      />

      <VieFullBlock
        children={
          <>
            <Fade delay={200}>
              <div>
                <BackgroundImage
                  Tag="section"
                  fluid={data.imageVieIsAboutLuxury.childImageSharp.fluid}
                  alt="Vie Deluxe Mattress side view"
                  backgroundColor={`#353a3c`}
                >
                  <div>
                    <h2>Vie is about luxury</h2>
                    <p>
                      Exquisite styling and uncompromising craftmanship. Vie
                      employ the finest materials. Premium, multi-layered memory
                      foams with cooling gel infusion are combined with deep
                      pocket springs to provide a luxuriously cool and
                      comfortable sleeping surface.
                    </p>
                  </div>
                </BackgroundImage>
              </div>
            </Fade>
          </>
        }
      />

      <VieTwoBlocks
        children={
          <>
            <Fade delay={200}>
              <div>
                <div style={{ background: "#00899c" }}>
                  <Img
                    Tag="section"
                    fluid={data.imageViePillowOnTealFront.childImageSharp.fluid}
                    alt="Vie Memory Hybrid Pillow on teal"
                    backgroundColor={`#353a3c`}
                    style={{ maxWidth: "560px" }}
                  />
                  <div>
                    <h2>Vie luxury memory pillow</h2>
                    <span>
                      <Link to="/#vie-pillow" style={{ color: "white" }}>
                        Read More&nbsp;
                        <ChevronRightSolid />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </Fade>

            <Fade delay={400}>
              <div>
                <div style={{ background: "#00899c" }}>
                  <Img
                    Tag="section"
                    fluid={data.imageVieMemoryOnTealSide.childImageSharp.fluid}
                    alt="Vie Memory Hybrid Mattress on grey"
                    backgroundColor={`#353a3c`}
                    style={{ maxWidth: "620px" }}
                  />
                  <div>
                    <h2>Vie in pictures</h2>
                    <span>
                      <Link to="/#vie-gallery" style={{ color: "white" }}>
                        Read More&nbsp;
                        <ChevronRightSolid />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </Fade>
          </>
        }
      />

      <div id="about-vie">
        <VieIntroduction
          heading="An introduction to Vie"
          description={
            <>
              <p>
                <strong>
                  Vie is a luxury hybrid mattress design company based in the
                  UK. Each of our products deliver exceptional quality,
                  ergonomic design and contemporary style.
                </strong>
              </p>

              <p>
                Every Vie mattress is manufactured to the highest industry
                standard and offers an advanced sleeping system of supportive
                layers which include cooling gel layers, premium memory foam and
                full size pocket springs to help improve circulation, keep you
                comfortable and cool and provide all over pressure relief.
              </p>

              <p>
                Our mattresses are delivered rolled, for practicality,
                convenience and ease of delivery and magically expand into a
                deep, sumptous sleeping surface that will provide long lasting
                use and a healthy, restorative sleep.
              </p>

              <p>
                Soft cover fabrics, hand crafted detailing and meticulous
                attention to detail further set Vie apart. Try a Vie mattress or
                pillow and enjoy a perfect blend of support and contoured
                comfort that is amongst the best in the world.
              </p>
            </>
          }
        />
      </div>

      <VieDeign
        image={
          <>
            <BackgroundImage
              Tag="section"
              fluid={data.imageVieDesign.childImageSharp.fluid}
              backgroundColor={`#353a3c`}
            />
          </>
        }
      />

      <div id="vie-mattress">
        <VieMattress
          heading="Luxury hybrid mattresses"
          image={
            <>
              <Img
                Tag="section"
                fluid={data.imageVieMemoryOnTealSide.childImageSharp.fluid}
                alt="Vie Deluxe Mattress on teal"
                backgroundColor={`#353a3c`}
                style={{ maxWidth: "960px" }}
              />
            </>
          }
          description={
            <>
              <p>
                The Vie mattress range combines the latest in hybrid technology.
                Multiple premium memory foam layers and high densities of deep
                pocket springs contour to your body to keep your spine aligned
                and supported. Cool Gel infused layers help diffuse body heat
                and an aerated edge to edge border adds further cooling,
                breathability and support and helps you move independently
                without disturbing your partner’s sleep.
              </p>
            </>
          }
        />
      </div>

      <div id="vie-life">
        <VieIsAbout />
      </div>

      <VieLove
        image={
          <>
            <BackgroundImage
              Tag="section"
              fluid={data.imageVieLove.childImageSharp.fluid}
              backgroundColor={`#353a3c`}
            />
          </>
        }
      />

      <div id="vie-pillow">
        <ViePillow
          heading="Luxury gel memory pillow"
          image={
            <>
              <Img
                Tag="section"
                fluid={data.imageViePillowOnTealFront.childImageSharp.fluid}
                alt="Vie Mattress Hybrid Pillow front"
                backgroundColor={`#353a3c`}
                style={{ maxWidth: "860px" }}
              />
            </>
          }
          description={
            <>
              <p>
                Vie luxury gel memory pillows utilise the latest advances in
                memory foam technology with advanced Cooling Gel to provide
                exceptional comfort and support. An ergonomically constructed
                exterior and interior adapts to the natural contours of the head
                and neck and regulates temperature and air-flow. Try the Vie
                pillow with any Vie mattress and discover a more relaxing sleep.
              </p>
            </>
          }
        />
      </div>

      <div id="vie-gallery">
        <VieGallery />
      </div>
    </>
  );
};
