import * as React from "react";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";

import s from "./FullBlockBackgroundImage.scss";

interface IFullBlockBackgroundImageProps {
  children: React.ReactNode;
}

export const FullBlockBackgroundImage = ({
  children
}: IFullBlockBackgroundImageProps) => (
  <div className={s.fullblockbackgroundimage}>
    <Container>
      <div className={s.fullblockbackgroundimage__block}>
        <Row>
          <div className={s.fullblockbackgroundimage__content}>{children}</div>
        </Row>
      </div>
    </Container>
  </div>
);
