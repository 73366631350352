import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Fade from "react-reveal/Fade";
import Popup from "reactjs-popup";
import Swiper from "react-id-swiper";

import { Container } from "components/container/Container";
import { Row } from "components/row/Row";

import ChevronLeftRegular from "assets/svg/chevron-left-regular.svg";
import ChevronRightRegular from "assets/svg/chevron-right-regular.svg";
import ChevronRightSolid from "assets/svg/chevron-right-solid.svg";
import TimesRegular from "assets/svg/times-regular.svg";

import s from "./VieGallery.scss";

export const VieGallery = () => {
  const data = useStaticQuery(graphql`
    fragment viegalleryImages on File {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query {
      imageVieGallery: file(relativePath: { eq: "vie-gallery.jpg" }) {
        ...viegalleryImages
      }
      imageVieMemoryHybridMattressOnTeal: file(
        relativePath: {
          eq: "products/vie-memory-mattress/vie-memory-hybrid-mattress-on-teal.jpg"
        }
      ) {
        ...viegalleryImages
      }
      imageVieMemoryHybridMattressClose: file(
        relativePath: {
          eq: "products/vie-memory-mattress/vie-memory-hybrid-mattress-close.jpg"
        }
      ) {
        ...viegalleryImages
      }
      imageVieMemoryHybridMattressDetailCover: file(
        relativePath: {
          eq: "products/vie-memory-mattress/vie-memory-hybrid-mattress-detail-cover.jpg"
        }
      ) {
        ...viegalleryImages
      }
      imageVieDeluxeHybridMattressOnTeal: file(
        relativePath: {
          eq: "products/vie-deluxe-mattress/vie-deluxe-hybrid-mattress-on-teal.jpg"
        }
      ) {
        ...viegalleryImages
      }
      imageVieDeluxeHybridMattressClose: file(
        relativePath: {
          eq: "products/vie-deluxe-mattress/vie-deluxe-hybrid-mattress-side-detail.jpg"
        }
      ) {
        ...viegalleryImages
      }
      imageVieDeluxeHybridMattressDetailCover: file(
        relativePath: {
          eq: "products/vie-deluxe-mattress/vie-deluxe-hybrid-mattres-piping-detail.jpg"
        }
      ) {
        ...viegalleryImages
      }
      imageViePremierHybridMattressOnTeal: file(
        relativePath: {
          eq: "products/vie-premier-mattress/vie-premier-hybrid-mattress-on-teal.jpg"
        }
      ) {
        ...viegalleryImages
      }
      imageViePremierHybridMattressEdgeDetail: file(
        relativePath: {
          eq: "products/vie-premier-mattress/vie-premier-hybrid-mattress-edge-detail.jpg"
        }
      ) {
        ...viegalleryImages
      }
      imageViePremierHybridMattressClose: file(
        relativePath: {
          eq: "products/vie-premier-mattress/vie-premier-hybrid-mattress-close.jpg"
        }
      ) {
        ...viegalleryImages
      }
      imageViePillowTop: file(
        relativePath: { eq: "products/vie-memory-pillow/vie-pillow-top.jpg" }
      ) {
        ...viegalleryImages
      }
      imageViePillowPackagingFront: file(
        relativePath: {
          eq: "products/vie-memory-pillow/vie-pillow-packaging-front.jpg"
        }
      ) {
        ...viegalleryImages
      }
      imageViePillowPackagingBack: file(
        relativePath: {
          eq: "products/vie-memory-pillow/vie-pillow-packaging-back.jpg"
        }
      ) {
        ...viegalleryImages
      }
    }
  `);
  const params = {
    slidesPerView: 1,
    preloadImages: false,
    zoom: {
      maxRatio: 5
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: true
    },
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom"
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev-custom">
        <ChevronLeftRegular />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next-custom">
        <ChevronRightRegular />
      </button>
    ),
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    }
  };
  return (
    <div className={s.viegallery}>
      <Popup
        trigger={
          <button>
            <Fade delay={200}>
              <BackgroundImage
                Tag="section"
                fluid={data.imageVieGallery.childImageSharp.fluid}
                backgroundColor={`#353a3c`}
              >
                <Container>
                  <div>
                    <Row>
                      <div>
                        <h2>
                          Gallery <ChevronRightSolid />
                        </h2>
                      </div>
                    </Row>
                  </div>
                </Container>
              </BackgroundImage>
            </Fade>
          </button>
        }
        modal
      >
        {close => (
          <div className={s.viegallery__modal}>
            <a className={s.viegallery__close} onClick={close}>
              <TimesRegular />
            </a>
            <div className={s.viegallery__content}>
              <h3>Vie Gallery</h3>
              <Swiper {...params}>
                <div>
                  <BackgroundImage
                    Tag="section"
                    fluid={
                      data.imageVieMemoryHybridMattressOnTeal.childImageSharp
                        .fluid
                    }
                    backgroundColor={`#353a3c`}
                  />
                  <span className={s.viegallery__title}>
                    Vie Memory Hybrid Mattress
                  </span>
                </div>
                <div>
                  <BackgroundImage
                    Tag="section"
                    fluid={
                      data.imageVieMemoryHybridMattressClose.childImageSharp
                        .fluid
                    }
                    backgroundColor={`#353a3c`}
                  />
                  <span className={s.viegallery__title}>
                    Vie Memory Hybrid Mattress - Close
                  </span>
                </div>
                <div>
                  <BackgroundImage
                    Tag="section"
                    fluid={
                      data.imageVieMemoryHybridMattressDetailCover
                        .childImageSharp.fluid
                    }
                    backgroundColor={`#353a3c`}
                  />
                  <span className={s.viegallery__title}>
                    Vie Memory Hybrid Mattress - Detail Cover
                  </span>
                </div>
                <div>
                  <BackgroundImage
                    Tag="section"
                    fluid={
                      data.imageVieDeluxeHybridMattressOnTeal.childImageSharp
                        .fluid
                    }
                    backgroundColor={`#353a3c`}
                  />
                  <span className={s.viegallery__title}>
                    Vie Deluxe Hybrid Mattress
                  </span>
                </div>
                <div>
                  <BackgroundImage
                    Tag="section"
                    fluid={
                      data.imageVieDeluxeHybridMattressClose.childImageSharp
                        .fluid
                    }
                    backgroundColor={`#353a3c`}
                  />
                  <span className={s.viegallery__title}>
                    Vie Deluxe Hybrid Mattress - Close
                  </span>
                </div>
                <div>
                  <BackgroundImage
                    Tag="section"
                    fluid={
                      data.imageVieDeluxeHybridMattressDetailCover
                        .childImageSharp.fluid
                    }
                    backgroundColor={`#353a3c`}
                  />
                  <span className={s.viegallery__title}>
                    Vie Deluxe Hybrid Mattress - Detail Cover
                  </span>
                </div>
                <div>
                  <BackgroundImage
                    Tag="section"
                    fluid={
                      data.imageViePremierHybridMattressOnTeal.childImageSharp
                        .fluid
                    }
                    backgroundColor={`#353a3c`}
                  />
                  <span className={s.viegallery__title}>
                    Vie Premier Hybrid Mattress
                  </span>
                </div>
                <div>
                  <BackgroundImage
                    Tag="section"
                    fluid={
                      data.imageViePremierHybridMattressEdgeDetail
                        .childImageSharp.fluid
                    }
                    backgroundColor={`#353a3c`}
                  />
                  <span className={s.viegallery__title}>
                    Vie Premier Hybrid Mattress - Edge Detail
                  </span>
                </div>
                <div>
                  <BackgroundImage
                    Tag="section"
                    fluid={
                      data.imageViePremierHybridMattressClose.childImageSharp
                        .fluid
                    }
                    backgroundColor={`#353a3c`}
                  />
                  <span className={s.viegallery__title}>
                    Vie Premier Hybrid Mattress - Close
                  </span>
                </div>
                <div>
                  <BackgroundImage
                    Tag="section"
                    fluid={data.imageViePillowTop.childImageSharp.fluid}
                    backgroundColor={`#353a3c`}
                  />
                  <span className={s.viegallery__title}>
                    Vie Memory Foam Pillow - Top
                  </span>
                </div>
                <div>
                  <BackgroundImage
                    Tag="section"
                    fluid={
                      data.imageViePillowPackagingFront.childImageSharp.fluid
                    }
                    backgroundColor={`#353a3c`}
                  />
                  <span className={s.viegallery__title}>
                    Vie Memory Foam Pillow - Packaging Front
                  </span>
                </div>
                <div>
                  <BackgroundImage
                    Tag="section"
                    fluid={
                      data.imageViePillowPackagingBack.childImageSharp.fluid
                    }
                    backgroundColor={`#353a3c`}
                  />
                  <span className={s.viegallery__title}>
                    Vie Memory Foam Pillow - Packaging Back
                  </span>
                </div>
              </Swiper>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};
